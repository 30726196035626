import React, { useEffect, useRef, useState } from "react";

// This function validates emails
const validateEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export default function Contact(props) {
  useEffect(() => {
    document.title = props.title;
    if (window.location.hash === "#book") {
      document.getElementById("book").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const form = useRef();
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [enteredName, setEnteredName] = useState("");
  const [enteredNameIsValid, setEnteredNameIsValid] = useState(false);
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);

  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailIsValid, setEnteredEmailIsValid] = useState(false);
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);

  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredPhoneIsValid, setEnteredPhoneIsValid] = useState(false);
  const [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false);

  const changeNameHandler = (e) => {
    setEnteredName(e.target.value);

    if (e.target.value.trim() !== "") {
      setEnteredNameIsValid(true);
    }
  };

  const nameInputBlurHandler = () => {
    setEnteredNameTouched(true);

    if (enteredName.trim() === "") {
      setEnteredNameIsValid(false);
      return;
    } else {
      setEnteredNameIsValid(true);
    }
  };

  const changeEmailHandler = (e) => {
    setEnteredEmail(e.target.value);

    if (validateEmail(e.target.value.trim() !== "")) {
      setEnteredEmailIsValid(true);
    }
  };

  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);

    if (enteredEmail.trim() === "") {
      setEnteredEmailIsValid(false);
      return;
    } else {
      setEnteredEmailIsValid(true);
    }
  };

  const changePhoneHandler = (e) => {
    setEnteredPhone(e.target.value);

    if (e.target.value.trim() !== "") {
      setEnteredPhoneIsValid(true);
    }
  };

  const phoneInputBlurHandler = () => {
    setEnteredPhoneTouched(true);

    if (enteredPhone.trim() === "") {
      setEnteredPhoneIsValid(false);
      return;
    } else {
      setEnteredPhoneIsValid(true);
    }
  };

  const onSubmit = (data) => {
    return fetch("https://backend.auth.easyhiring.in/home" + "/book-demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => console.log(err));
  };

  const submit = (e) => {
    e.preventDefault();

    setMessage("");
    setEnteredNameTouched(true);
    setEnteredEmailTouched(true);
    setEnteredPhoneTouched(true);

    if (enteredName.trim() === "") {
      setEnteredNameIsValid(false);
      return;
    }
    if (enteredEmail.trim().length === "") {
      setEnteredEmailIsValid(false);
      return;
    }
    if (enteredPhone.trim().length === "") {
      setEnteredPhoneIsValid(false);
      return;
    }

    setLoading(true);

    onSubmit({ name: enteredName, email: enteredEmail, phone: enteredPhone })
      .then((res) => {
        setMessage(res.message);
        setSuccess(true);
        setEnteredName("");
        setEnteredEmail("");
        setEnteredPhone("");
      })
      .catch((err) => {
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <section className="home-banner-area">
        <div className="container">
          {/* <div className="row justify-content-center align-items-center">
          <div className="col-lg-10 banner-right">
            <h1 className="text-white">
              Contact
            </h1>
            <p className="mx-auto text-white mb-40">
            Getting excited already?
            </p>
            <div className="link-nav">
              <span className="box">
                <Link to="/">Home </Link>
                <i className="lnr lnr-arrow-right"></i>
                <Link to="/contact">Contact</Link>
              </span>
            </div>
          </div>
        </div> */}
          <div className="row justify-content-between fullscreen align-items-center py-5">
            <div className="col-lg-6 col-md-12 home-banner-left text-left pb-5">
              <h1 className="text-white text-left">Contact Us</h1>
            </div>
            <div className="col-lg-6 col-md-12 home-banner-right">
              <img
                className="img-fluid animate-this mt-5"
                src="assets/img/12982910_5124556.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-area section-gap" id="book">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex flex-column address-wrap">
              <div className="single-contact-address d-flex flex-row">
                <div className="icon">
                  <span className="lnr lnr-home"></span>
                </div>
                <div className="contact-details">
                  <h4 className="mb-2">
                    <strong>Address</strong>
                  </h4>
                  <p className="text-dark">
                    <a
                      class="text-dark"
                      href="https://goo.gl/maps/gu8pcd1Db11H2JvY8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GVR Infra Rd Number 12, <br />
                      Ayyappa Society
                      <br />
                      Madhapur, Hyderabad
                      <br />
                      Telangana, India,
                    </a>
                  </p>
                </div>
              </div>

              <div className="single-contact-address d-flex flex-row">
                <div className="icon">
                  <span className="lnr lnr-envelope"></span>
                </div>
                <div className="contact-details">
                  <h4 className="mb-2">Email</h4>
                  <p>
                    <a className="text-dark" href="mailto:info@easyhiring.in">
                      info@easyhiring.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <h4 id="successmsg" className="mb-4"></h4>
              <form
                className="form-area contact-form text-right"
                ref={form}
                onSubmit={submit}
              >
                {message && (
                  <p class="alert-success alert text-left" role="alert">
                    {message}
                  </p>
                )}
                <div className="row">
                  <div className="col-lg-6 form-group">
                    <input
                      name="user_name"
                      value={enteredName}
                      onChange={changeNameHandler}
                      onBlur={nameInputBlurHandler}
                      placeholder="Enter your name"
                      className="common-input mb-20 form-control"
                      required=""
                      type="text"
                    />

                    <input
                      name="user_email"
                      placeholder="Enter email address"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      className="common-input mb-20 form-control"
                      required=""
                      type="email"
                      value={enteredEmail}
                      onChange={changeEmailHandler}
                      onBlur={emailInputBlurHandler}
                    />

                    <input
                      name="user_phone"
                      placeholder="Enter phone number"
                      pattern="[0-9]{10}"
                      className="common-input mb-20 form-control"
                      required=""
                      type="tel"
                      value={enteredPhone}
                      onChange={changePhoneHandler}
                      onBlur={phoneInputBlurHandler}
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <textarea
                      className="common-textarea form-control"
                      name="message"
                      placeholder="Any queries? We are here to help you out!"
                      required=""
                    ></textarea>
                  </div>
                  <div className="col-lg-12">
                    <div className="alert-msg text-left"></div>
                    <input
                      type="submit"
                      className="btn float-right"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-5 section-title">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <h2>FAQs</h2>
              <div id="accordion">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      What is the program duration?
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      For both programs self-paced and mentor-led, the duration
                      is of 2 months. You are allowed to learn at your own pace
                      and every course is designed to make you ready for the
                      future skills.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-toggle="collapse"
                      href="#collapseTwo"
                    >
                      Can I pay the fee in installments?
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      Yes, you can pre-register by paying 1000 INR as
                      pre-registration to book your slot and the balance can be
                      paid later before starting the program.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-toggle="collapse"
                      href="#collapseThree"
                    >
                      What are the modes of payments available?
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      You may choose to pay through Debit/Credit cards and
                      Internet banking. In addition, we also provide E-wallet
                      options and direct UPI transfers through Google Pay,
                      Paytm, PhonePe as well.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-toggle="collapse"
                      href="#collapseFour"
                    >
                      What time our classes will be?
                    </a>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      All the classes are in the evening after 6’O clock.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <img
                className="animate-this"
                decoding="async"
                src="assets/img/urban-208.gif"
                alt="Career Success"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
