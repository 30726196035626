import { Link, NavLink } from "react-router-dom";

export default function Header() {
  return (
    <header className="default-header">
      <nav className="navbar navbar-expand-lg  navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="assets/img/logo.png" alt="" className="w-25" />{" "}
            <span className="logotxt">Easy Hiring</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="lnr lnr-menu"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end align-items-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/testimonials"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Testimonials
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                }}
              >
                <a
                  href="https://auth.easyhiring.in/login"
                  target="_blank"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    color: "black",
                  }}
                  rel="noreferrer"
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
