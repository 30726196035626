import React, { useEffect, useRef, useState } from "react";

export const testimonials = [
  {
    content:
      "I am placed in Wipro, Accenture, Infosys. Easy Hiring's placement course played a major role in my success. Thanks to instructors for their flawless teaching and technical team for building tool to generate reports which helps to strengthen our weak areas too.",
    name: "Sudha Rani",
    designation: "Associate Engineer at Wipro",
    photo: "testimonials/sudha-rani.jpeg",
  },
  {
    content:
      "The content provided by you is great, mock interviews helped me alot to improve myself. Mainly the reports that are generated after everytest has helped me to understand what my weakness is and content videos helped me to strengthen the same. And the question structures are pretty similar to most of the questions that I had faced in the actual test. I am so happy for every penny that I have spent for placement course in Easy Hiring.",
    name: "Manasa Kodari",
    designation: "Assistant System Engineer - TCS",
    photo: "testimonials/manasa-kodari.jpeg",
  },
  {
    content:
      "The support provided by Easy Hiring team is inimitable! Reports for self analysis and Company Specific training made me to crack jobs in Infosys and Amazon.",
    name: "Venkata Sunil",
    designation: "Analyst - Amazon",
    photo: "testimonials/venkata-sunil.jpeg",
  },
  {
    content:
      "In terms of content, company specific training, Support we get from team when we need is Fabulous. ",
    name: "Sai Govind",
    designation: "Associate System Engineer - IBM",
    photo: "testimonials/sai-govind.jpeg",
  },
  {
    content:
      "I was not good in aptitude and logical earlier but now i can say that I am a pro in aptitude after completing the videos and mock tests of them. Shortcuts,tricks and content taught in videos was very easy to understand and all this has helped me to crack a job in Tech Mahindra and Wipro. Thankyou Easy Hiring, for teaching those concepts in best possible way.",
    name: "Jyothieswar Reddy",
    designation: "Associate Engineer - Tech Mahindra",
    photo: "testimonials/jyothieswar-reddy.jpeg",
  },
];

const otherTestimonials = [
  {
    name: "Madhu Kodaru",
    designation: "Graduate Trainee at HCL ",
    content:
      "Easy Hiring has been a game-changer for me. Their upskilling programs and personalized learning approach helped me land a dream job in the AI field. Highly recommended!",
    photo: "testimonials/madhu-kodaru.jpeg",
  },
  {
    name: "Radha krishna Thotakura",
    designation: "Associate software engineer at TCS",
    content:
      "As a university, we have partnered with Easy Hiring for our students' placement needs. Their dedicated team ensures that our students receive quality internship opportunities and real-time projects to enhance their skills. It's been a fruitful collaboration.",
    photo: "testimonials/radha-krishna-thotakura.jpeg",
  },
  {
    name: "Harsha Duggiralaha",
    designation: "Program Associate at Factly",
    content:
      "I enrolled in Easy Hiring's Data Science program, and it exceeded my expectations. The curriculum was well-structured, and the instructors were knowledgeable and supportive. I feel confident in my abilities now.",
    photo: "testimonials/harsha-duggiralaha.jpeg",
  },
  {
    name: "Aravind Krishna",
    designation: "Software Analysts at Capgemini",
    content:
      "Easy Hiring understands the importance of practical experience. Their inclusion of real-time live projects helped me bridge the gap between theory and practice, giving me an edge in the competitive job market.",
    photo: "testimonials/aravind-krishna.jpeg",
  },
  {
    name: "Bhargav Kodaru",
    designation: "Software engineer at Persistent Systems",
    content:
      "The Analytical Skills course offered by Easy Hiring was a fantastic foundation for my career. It sharpened my problem-solving abilities and boosted my confidence. I'm grateful for the strong start they provided.",
    photo: "testimonials/bhargav-kodaru.jpeg",
  },
  {
    name: "Divya morampodi",
    designation: "Associate systems engineer at Tech Mahindra",
    content:
      "Easy Hiring's internship program was a valuable stepping stone for me. I gained hands-on experience and built a network of industry professionals. It made a significant impact on my career trajectory.",
    photo: "testimonials/divya-morampodi.jpeg",
  },
  {
    name: "Yeswanth rajanna",
    designation: "Quality specialist at Amazon",
    content:
      "The instructors at Easy Hiring are top-notch. They are experts in their respective fields and are always willing to go the extra mile to ensure students' understanding. Their guidance was invaluable.",
    photo: "testimonials/yeswanth-rajanna.jpeg",
  },
  {
    name: "Durga Bhavani",
    designation: "LMAQ analyst at Amazon",
    content:
      "Easy Hiring's focus on individual learning patterns is commendable. They tailor the content to match each student's needs, ensuring a personalized and effective learning experience.",
    photo: "testimonials/durga-bhavani.jpeg",
  },
  {
    name: "Jagan Kumar",
    designation: "Quality specialist at Amazon",
    content:
      "I joined Easy Hiring's Full Stack program, and it transformed my skills. The comprehensive curriculum covered all the necessary aspects, and I now feel confident in my ability to develop robust web applications.",
    photo: "testimonials/jagan-kumar.jpeg",
  },
  {
    name: "Santosh",
    designation: "Apprentice at CTS",
    video: "testimonials/santosh-cropped.mp4",
  },
  {
    name: "Sreenivasulu",
    designation: "Associate software engineer at TCS",
    content:
      "Easy Hiring's dedication to student success is evident. They provide ongoing support even after the program ends, offering career guidance and job placement assistance. It's a company that truly cares about its students.",
    photo: "testimonials/sreenivasulu.jpeg",
  },
  {
    name: "Shivani Nuligonda",
    designation: "System Engineer at Infosys",
    content:
      "I am amazed at the range of programs Easy Hiring offers. Whether you're interested in AI, ML, Data Science, or Web Development, they have something for everyone. It's a one-stop-shop for upskilling.",
    photo: "testimonials/shivani-nuligonda.jpeg",
  },
  {
    name: "Sravan kumar reddy Kakunuri",
    designation: "VLSI design engineer at Texas instruments",
    content:
      "Easy Hiring's commitment to quality is unparalleled. The materials provided are thorough and up-to-date, and the assessments challenge you to apply what you've learned. I'm grateful for the high standards they maintain.",
    photo: "testimonials/sravan-kumar-reddy-kakunuri.jpeg",
  },
  {
    name: "Naresh Pakalapati",
    designation: "Genc developer at CTS",
    content:
      "I highly recommend Easy Hiring's internship program. It provided me with real-world experience, allowing me to showcase my skills to potential employers. I secured a job offer even before completing my studies.",
    photo: "testimonials/naresh-pakalapati.jpeg",
  },
  {
    name: "Ravindra Kaligiri",
    designation: "Ml Engineer at Bosch ",
    content:
      "Easy Hiring's focus on industry-relevant skills is impressive. They prepare students for the demands of the job market by teaching the latest technologies and providing exposure to real-life projects.",
    photo: "testimonials/ravindra-kaligiri.jpeg",
  },
  {
    name: "Venkat reddy",
    designation: "Database Administrator at Oracle",
    content:
      "The learning platform used by Easy Hiring is user-friendly and engaging. The interactive lessons and quizzes kept me motivated throughout the program. Learning became an enjoyable experience.",
    photo: "testimonials/venkat-reddy.jpeg",
  },
  {
    name: "Deeraj krishna dukuru",
    designation: "AI engineer at Mphasis",
    content:
      "Easy Hiring helped me transition from a non-technical background to a career in programming. Their coding courses were well-structured, and the instructors provided clear explanations. I'm grateful for the opportunity they provided.",
    photo: "testimonials/deeraj-krishna-dukuru.jpeg",
  },
  {
    name: "Aravind Kumari",
    designation: " Genc developer at CTS",
    content:
      "Easy Hiring's placement opportunities are excellent. They have a wide network of partner companies, and they actively connect students with relevant job openings. It significantly simplifies the job search process.",
    photo: "testimonials/aravind-kumari.jpeg",
  },
  {
    name: "Anil kumar pathgunta",
    designation: "Automation tester at Oracle",
    content:
      "The internship I completed through Easy Hiring was eye-opening. I got to work on real projects alongside experienced professionals, which boosted my confidence and expanded my skill set. It was a valuable experience.",
    photo: "testimonials/anil-kumar-pathgunta.jpeg",
  },
  {
    name: "Uday kumar",
    designation: "Web developer at Persistent Systems",
    content:
      "Easy Hiring's mentorship program was a game-changer for me. I received personalized guidance from industry experts, helping me identify my strengths and areas for improvement. It gave me the confidence to pursue my career goals.",
    photo: "testimonials/uday-kumar.jpeg",
  },
  {
    name: "Charkadhar Reddy",
    designation: "Associate software trainee at Accenture",
    content:
      "Easy Hiring's dedication to fostering a supportive learning community sets them apart. Through discussion forums and collaboration opportunities, I connected with fellow students and built valuable relationships that continue even after completing the program.",
    photo: "testimonials/charkadhar-reddy.jpeg",
  },
  {
    name: "Kota Raja Reddy",
    designation: "Software trainee at UST global",
    content:
      "Easy Hiring has transformed my career journey! Their comprehensive upskilling programs helped me enhance my technical skills and land my dream job in data science.",
    photo: "testimonials/kota-raja-reddy.jpeg",
  },

  {
    name: "Raghav",
    designation: "Software Intern at Delloite",
    video: "testimonials/raghav.mp4",
  },
  {
    name: "Radha krishna varampodi",
    designation: "System Engineer at Infosys",
    content:
      "Thanks to Easy Hiring, I gained valuable industry experience through their internship opportunities. It gave me the confidence and practical skills needed to excel in my field.",
    photo: "testimonials/radha-krishna-varampodi.jpeg",
  },
  {
    name: "Mohan sai Nuvvula",
    designation: "Software engineer trainee at DXC technologies",
    content:
      "Easy Hiring's personalized learning approach helped me identify my individual learning pattern, allowing me to grasp complex concepts more efficiently. Their content curation made learning a breeze!",
    photo: "testimonials/mohan-sai-nuvvula.jpeg",
  },
  {
    name: "Vinay Islavath",
    designation: "Software Analysts at Capgemini",
    content:
      "I can't recommend Easy Hiring enough! Their placement opportunities connected me with top companies in the tech industry, opening doors to incredible career prospects.",
    photo: "testimonials/vinay-islavath.jpeg",
  },
  {
    name: "Teja poripi reddy",
    designation: "Associate software trainee at Accenture",
    content:
      "The analytical skills taught by Easy Hiring were invaluable in cracking interviews with multinational corporations. They provided the foundation I needed to succeed.",
    photo: "testimonials/teja-poripi-reddy.jpeg",
  },
  {
    name: "Praveen Kaanala",
    designation: "Machine learning engineer at Tensor go technologies",
    content:
      "Easy Hiring's focus on coding using Java and Python equipped me with the necessary programming skills to excel in any tech role. Their teaching methods are top-notch!",
    photo: "testimonials/praveen-kaanala.jpeg",
  },
  {
    name: "Kavya Sanikommu",
    designation: "Associate software engineer at Wipro",
    content:
      "The AI program offered by Easy Hiring was comprehensive and up-to-date with the latest industry trends. I felt well-prepared to tackle real-world challenges.",
    photo: "testimonials/kavya-sanikommu.jpeg",
  },
  {
    name: "Sreenu Ragampodi",
    designation: "Software trainee at Wipro",
    content:
      "Easy Hiring's ML program exceeded my expectations! The hands-on projects allowed me to apply my knowledge and build a strong portfolio.",
    photo: "testimonials/sreenu-ragampodi.jpeg",
  },
  {
    name: "Niteesha Jakkampudi",
    designation: "Software Analysts at Capgemini",
    content:
      "I enrolled in Easy Hiring's Data Science program, and it was a game-changer! The mentors were knowledgeable, and the curriculum was well-structured.",
    photo: "testimonials/niteesha-jakkampudi.jpeg",
  },
  {
    name: "Vyshnavi Teegala",
    designation: "Associate software trainee at Accenture",
    content:
      "Easy Hiring's Full Stack program provided me with a well-rounded skill set. I now feel confident working on both the front-end and back-end of web development projects.",
    photo: "testimonials/vyshnavi-teegala.jpeg",
  },
  {
    name: "Vishnu  Kanchanapally",
    designation: "Program Analyst at Mind tree",
    content:
      "The internship I secured through Easy Hiring's platform gave me the opportunity to work on real-time projects, making me industry-ready from day one.",
    photo: "testimonials/vishnu--kanchanapally.jpeg",
  },
  {
    name: "Kavya",
    designation: "Software Trainee at Wipro",
    video: "testimonials/kavya.mp4",
  },
  {
    name: "Sharath Gadike",
    designation: "Program Analyst at Vector systems",
    content:
      "Easy Hiring's content curation tailored to my learning style helped me understand complex topics easily. Learning became enjoyable and less intimidating.",
    photo: "testimonials/sharath-gadike.jpeg",
  },
  {
    name: "Navya Sanikommu",
    designation: "Software Analyst at ADP",
    content:
      "Easy Hiring goes above and beyond to support students. Their career guidance and mentorship programs helped me navigate the job market with confidence.",
    photo: "testimonials/navya-sanikommu.jpeg",
  },
];

function groupArr(data, n) {
  var group = [];
  for (var i = 0, j = 0; i < data.length; i++) {
    if (i >= n && i % n === 0) j++;
    group[j] = group[j] || [];
    group[j].push(data[i]);
  }
  return group;
}

const Testimonials = () => {
  const groups = groupArr([...testimonials, ...otherTestimonials], 3);
  return (
    <>
      <section className="home-banner-area">
        <div className="container">
          {/* <div className="row justify-content-center align-items-center">
          <div className="col-lg-10 banner-right">
            <h1 className="text-white">
              Contact
            </h1>
            <p className="mx-auto text-white mb-40">
            Getting excited already?
            </p>
            <div className="link-nav">
              <span className="box">
                <Link to="/">Home </Link>
                <i className="lnr lnr-arrow-right"></i>
                <Link to="/contact">Contact</Link>
              </span>
            </div>
          </div>
        </div> */}
          <div className="row justify-content-between fullscreen align-items-center py-5">
            <div className="col-lg-6 col-md-12 home-banner-left text-left pb-5">
              <h1 className="text-white text-left">
                <div className="col-lg-12 text-left">
                  <p className="text-white">
                    <strong>What People Say About Us</strong>
                  </p>
                  <h2 className="text-white">Happy Students!</h2>
                </div>
              </h1>
            </div>
            <div className="col-lg-6 col-md-12 home-banner-right">
              <img
                className="img-fluid animate-this mt-5"
                src="assets/img/testimonials-bg-new.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="other-feature-area" style={{ marginTop: 0 }}>
        <div className="container">
          <div className="feature-inner row">
            <div class="row">
              {groups.map((group) => {
                return group.map((item, index) => {
                  const mtClass = "";
                  // index === 1 ? "mt--160" : index === 2 ? "mt--260" : "";
                  return (
                    <div
                      data-aos-mirror="true"
                      className={`col-lg-4 col-md-6 ${mtClass}`}
                    >
                      <div className="other-feature-item">
                        <div
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          {item.photo ? (
                            <img
                              style={{ height: "80px", width: "80px" }}
                              src={item.photo}
                              alt=""
                            />
                          ) : (
                            <video
                              src={item.video}
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                objectFit: "cover",
                                height: "240px",
                                width: "100%",
                              }}
                              controls
                            />
                          )}
                        </div>
                        <div>
                          <p>{item.content}</p>
                        </div>
                        <h4>
                          {item.name} - {item.designation}
                        </h4>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
