import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer-area section-gap-top">
        <div className="container">
          <div className="row pt-5 text-left">
            <div className="col-lg-4 col-md-6 single-footer-widget">
              <h4>Address</h4>
              <p className="d-flex align-items-baseline pr-4">
                <i className="fa fa-map mr-3"></i> GVR Infra Rd Number 12,{" "}
                <br />
                Ayyappa Society <br />
                Madhapur, Hyderabad <br />
                Telangana, India
              </p>
              <p className="d-flex align-items-baseline">
                <i className="fa fa-envelope mr-3"></i>{" "}
                <a href="mailto:info@easyhiring.in">info@easyhiring.in</a>
              </p>
            </div>
            <div className="col-lg-4 col-md-6 single-footer-widget">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <a href="https://auth.easyhiring.in/refund">Refund policy</a>
                </li>
                <li>
                  <a href="https://auth.easyhiring.in/cancellation">
                    Cancellation policy
                  </a>
                </li>
                <li>
                  <a href="https://auth.easyhiring.in/privacy">
                    Privacy policy
                  </a>
                </li>
                <li>
                  <a href="https://auth.easyhiring.in/terms">
                    Terms and conditions
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 single-footer-widget">
              <h4>Newsletter</h4>
              <p>You can trust us. we only send promo offers,</p>
              <div className="form-wrap" id="mc_embed_signup">
                <form
                  target="_blank"
                  action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                  method="get"
                  className="form-inline"
                >
                  <input
                    className="form-control"
                    name="EMAIL"
                    placeholder="Your Email Address"
                    required=""
                    type="email"
                  />
                  <button className="click-btn btn btn-default text-uppercase">
                    subscribe
                  </button>

                  <div className="info"></div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-bottom mt-5 row align-items-center">
            <p className="footer-text m-0 col-lg-8 col-md-12">
              {" "}
              &copy; {`${new Date().getFullYear()}`} Easy Hiring Infotech LLP.
              All rights reserved.
            </p>
            <div className="col-lg-4 col-md-12 footer-social">
              <a
                href="https://www.facebook.com/easyhiring.in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://www.twitter.com/easyhiring_in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/easyhiring.in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/easyhiring-in/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
