import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { testimonials } from "./Testimonials";

const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  items: 1,
  loop: true,
  autoplay: false,
  navText: [
    "<img src='assets/img/prev.png'>",
    "<img src='assets/img/next.png'>",
  ],
  smartSpeed: 1000,
};

// This function validates emails
const validateEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export default function Homepage(props) {
  const [message, setMessage] = React.useState("");

  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [enteredName, setEnteredName] = useState("");
  const [enteredNameIsValid, setEnteredNameIsValid] = useState(false);
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);

  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailIsValid, setEnteredEmailIsValid] = useState(false);
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);

  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredPhoneIsValid, setEnteredPhoneIsValid] = useState(false);
  const [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false);

  const changeNameHandler = (e) => {
    setEnteredName(e.target.value);

    if (e.target.value.trim() !== "") {
      setEnteredNameIsValid(true);
    }
  };

  const nameInputBlurHandler = () => {
    setEnteredNameTouched(true);

    if (enteredName.trim() === "") {
      setEnteredNameIsValid(false);
      return;
    } else {
      setEnteredNameIsValid(true);
    }
  };

  const changeEmailHandler = (e) => {
    setEnteredEmail(e.target.value);

    if (validateEmail(e.target.value.trim() !== "")) {
      setEnteredEmailIsValid(true);
    }
  };

  const emailInputBlurHandler = () => {
    setEnteredEmailTouched(true);

    if (enteredEmail.trim() === "") {
      setEnteredEmailIsValid(false);
      return;
    } else {
      setEnteredEmailIsValid(true);
    }
  };

  const changePhoneHandler = (e) => {
    setEnteredPhone(e.target.value);

    if (e.target.value.trim() !== "") {
      setEnteredPhoneIsValid(true);
    }
  };

  const phoneInputBlurHandler = () => {
    setEnteredPhoneTouched(true);

    if (enteredPhone.trim() === "") {
      setEnteredPhoneIsValid(false);
      return;
    } else {
      setEnteredPhoneIsValid(true);
    }
  };

  const onSubmit = (data) => {
    return fetch("https://backend.auth.easyhiring.in/home" + "/book-demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => console.log(err));
  };

  const submit = (e) => {
    e.preventDefault();

    setMessage("");
    setEnteredNameTouched(true);
    setEnteredEmailTouched(true);
    setEnteredPhoneTouched(true);

    if (enteredName.trim() === "") {
      setEnteredNameIsValid(false);
      return;
    }
    if (enteredEmail.trim().length === "") {
      setEnteredEmailIsValid(false);
      return;
    }
    if (enteredPhone.trim().length === "") {
      setEnteredPhoneIsValid(false);
      return;
    }

    setLoading(true);

    onSubmit({ name: enteredName, email: enteredEmail, phone: enteredPhone })
      .then((res) => {
        setMessage(res.message);
        setSuccess(true);
        setEnteredName("");
        setEnteredEmail("");
        setEnteredPhone("");
      })
      .catch((err) => {
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <>
      <section className="home-banner-area pb-5">
        <div className="container py-5">
          <div className="row justify-content-between fullscreen align-items-center py-5">
            <div className="col-lg-6 col-md-8 home-banner-left text-left pb-5">
              <h1 className="text-white text-left">
                Learn from the best and get your dream job.
              </h1>
              <p className="mx-auto text-white text-left mt-20 mb-40">
                Get trained from the professionals for advanced experience
              </p>
              <a href="#book" className="genric-btn default circle arrow">
                Book a demo<span className="lnr lnr-arrow-right"></span>
              </a>
            </div>
            <div className="col-lg-6 col-md-12 home-banner-right">
              <img
                className="img-fluid animate-this"
                src="assets/img/header-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ End banner Area ================= --> */}

      {/* <!-- ================ Start Feature Area ================= --> */}
      <section className="feature-area">
        <div className="container">
          <div class="row">
            <div class="col-lg-6 mb-3">
              <h2 class="text-center mb-3">Video based learning</h2>
              <p class="text-center">
                The explanation refers to learning experience which facilitates
                through video. It relies on covering remote training as in
                preaches methods like aptitude, verbal, logical, programming
                languages, and general communication skills from the experts.
              </p>
            </div>
          </div>
          <div className="feature-inner row">
            <div className="col-lg-3 col-md-6">
              <div className="feature-item d-flex">
                <i className="ti-view-list-alt"></i>
                <div className="ml-20">
                  <h4>
                    It delivers the overview of the topic with given examples.
                  </h4>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="feature-item d-flex">
                <i className="ti-layout-grid4"></i>
                <div className="ml-20">
                  <h4>
                    To build inherit learning with the help of strategies and
                    approach.
                  </h4>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="feature-item d-flex border-right-0">
                <i className="ti-layout-media-right-alt"></i>
                <div className="ml-20">
                  <h4>
                    We provide a file imported with all the short cut methods,
                    formulas and procedures for every given topic.
                  </h4>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="popular-course-area section-gap">
        <div className="container">
          <div className="row justify-content-between align-items-center section-title pb-4">
            <div className="col-lg-5 text-left">
              <img
                className="img-fluid animate-this"
                src="assets/img/about-img.png"
                alt=""
              />
            </div>
            <div className="col-lg-5 text-left">
              <p className="text-dark">
                <strong>Get Certified</strong>
              </p>
              <h2 className="text-dark">Our certifications</h2>
              <p>
                Easy Hiring was founded with the aim to educate students in
                order to make them future ready by providing a curation of
                courses created by industry leaders with years of experience in
                their respective fields. With our unique certifications, you
                will be vouched for being a professional.
              </p>
              <p>Our Certifications:</p>
              <ul className="unordered-list pt-4">
                <li>Internship Completion Certificate</li>
                <li>Project Completion Certificate</li>
              </ul>
              <a href="#" className="genric-btn primary circle arrow mt-4">
                Know More<span className="lnr lnr-arrow-right"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ End Feature Area ================= --> */}

      {/* <!-- ================ Start Video Area ================= --> */}
      <section className="counter-area section-gap">
        <div className="container section-gap">
          <div className="row align-items-center">
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp
                    end={100}
                    redraw={true}
                    suffix="+"
                    scrollSpyOnce={true}
                  >
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">Learning Hours</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={50} redraw={true} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">INDUSTRY EXPERTS</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={25000} redraw={true} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">ACTIVE LEARNERS</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={100} redraw={true} suffix="%">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">STUDENT SATISFACTION</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-area placement-opp-sec">
        <div className="container">
          <div className="row align-items-center pt-5 pb-5">
            <div className="col-lg-5 pt-5 pb-5">
              <div className="section-title text-dark text-left pb-0">
                <p className="text-dark">
                  <strong>Why Choose Us?</strong>
                </p>
                <h2 className="text-dark">Placement Opportunities</h2>
                <p>
                  Many students might be wondering if each student will attain
                  the job with surety. Well, this is a platform for all those
                  students who are looking for skilled and experienced jobs and
                  who look to create blissful opportunities over time. The
                  students can attend the drives directly, the report is
                  verified on the basis of their monthly or weekly test
                  performances.
                </p>
                <ul className="unordered-list">
                  <li>Procure a chance to get interviewed by the company.</li>
                  <li>
                    Experience the virtual interactions with the industry
                    experts.
                  </li>
                  <li>
                    Company specific one on one interview conducted by the
                    professionals.
                  </li>
                </ul>
                <a href="#" className="genric-btn primary circle arrow mt-4">
                  Know More<span className="lnr lnr-arrow-right"></span>
                </a>
              </div>
            </div>
            <div className="offset-lg-1 col-md-6 video-left mt-5 pb-5">
              <div className="">
                <div className="single-video mt-5">
                  <div className="video-part animate-this">
                    <img
                      className="img-fluid"
                      src="assets/img/urban-856.gif"
                      alt=""
                    />
                    {/* <div className="overlay"></div> */}
                    {/* <a className="popup-youtube play-btn" href="https://www.youtube.com/watch?v=VufDd-QL1c0">
                  <img className="play-icon" src="assets/img/play-btn.png" alt="" />
                </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ================ End Video Area ================= --> */}

      {/* <!-- ================ Start Feature Area ================= --> */}
      <section className="other-feature-area">
        <div className="container">
          <div className="feature-inner row">
            <div className="col-lg-12">
              <div className="section-title text-left animate-this">
                <h2>
                  Popular <br /> Programs
                </h2>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6">
              <div className="other-feature-item">
                <i className="ti-server"></i>
                <h4>Data Science</h4>
                <div>
                  <p>
                    From statistics to machine learning algorithms, Data Science
                    is a vast field. In this program you'll be taught to use all
                    the tools required and identify trends, patterns and gain
                    insights from raw data as required in the industry. You will
                    leave this program having learnt how to validate and support
                    big business decisions with facts and figures
                  </p>
                </div>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--160">
              <div className="other-feature-item">
                <i className="ti-layout-slider"></i>
                <h4>Web Development</h4>
                <div>
                  <p>
                    Web Development is the building and maintenance of websites
                    to make sure they look good, perform well, work fast, and
                    offer a seamless user experience. To do this one needs
                    expertise on concepts of HTML, CSS & JS. So, the internal
                    working of all of them are taught to make the students
                    industry ready by the time they complete this program.
                  </p>
                </div>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--260">
              <div className="other-feature-item">
                <i className="ti-shield"></i>
                <h4>Backend development + DBA</h4>
                <div>
                  <p>
                    Backend Development is the programming that goes behind the
                    scenes of a website or application. It consists of a server,
                    an application, and a database, manages user connections,
                    and powers the web application itself. In this program
                    you'll be taught all the concepts of backend development
                    along with the concepts of Database Administration.
                  </p>
                </div>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6">
              <div className="other-feature-item">
                <i className="ti-briefcase"></i>
                <h4>Full stack</h4>
                <div>
                  <p>
                    Full Stack Development is the development of both front end
                    and back end portions of an application. Full Stack
                    Developers are developers that design complete apps and
                    websites. They work on all facets of development, from
                    frontend, to backend, to database and even debugging and
                    testing. This program will teach you how to design complete
                    apps and websites.
                  </p>
                </div>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--160">
              <div className="other-feature-item">
                <i className="ti-vector"></i>
                <h4>Machine Learning</h4>
                <div>
                  <p>
                    Data Analysis, Algorithms, Predictive Modelling. Machine
                    Learning is a domain with endless possibilities. Almost all
                    of their internal working and the practical implementation
                    of it is explained so that you will leave this program with
                    all the tools and knowledge to develop programs and
                    algorithms to suit whatever the needs may be as per problem
                    statement.
                  </p>
                </div>
              </div>
            </div>
            <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--260">
              <div className="other-feature-item">
                <i className="ti-headphone-alt"></i>
                <h4>Artificial Intelligence</h4>
                <div>
                  <p>
                    Artificial Intelligence refers to the simulation of human
                    intelligence in machines that are programmed to think like
                    humans and mimic their actions. To do this we need a lot of
                    understanding about the various algorithms, processing the
                    data, Analyzing the feature importance. By the end of this
                    program, you would be able to master all of these and become
                    industry ready.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="popular-course-area section-gap">
        <div className="container">
          <div className="row justify-content-center section-title">
            <div className="col-lg-12 text-center">
              <p class="text-dark text-center mx-auto">
                <strong>Our Process</strong>
              </p>
              <h2>A Quick Guide</h2>
            </div>
          </div>

          <div class="maxcoach-timeline style-01">
            <div class="timeline-line"></div>
            <div class="timeline-list maxcoach-entrance-animation-queue pt-0">
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-1026.gif"
                          alt="Register With Us"
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Register With Us</h5>
                        <p>
                          Fill your details in the Registration form and after
                          that our student counsellor will contact you and guide
                          you for the further process
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-solving-math-problem.gif"
                          alt="Learn advanced courses"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Learn advanced courses</h5>
                        <p>
                          Master industry relevant advanced courses with expert
                          guidance and level up your career.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-online-education-4.gif"
                          alt="Complete Your Course"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Don’t just learn. DO!</h5>
                        <p>
                          The ultimate project-based learning platform for
                          aspiring professionals that helps gain skills by
                          building industry standard projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-web-design.gif"
                          alt="Earn Your Certificate"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Get a Certificate </h5>
                        <p>
                          Internship certficate
                          <br />
                          Project Completion certificate
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-brainstorm.gif"
                          alt="Placement Assistance"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Placement Support </h5>
                        <p>
                          Profile evaluation and Guidance from industry experts
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeline-item item">
                <div class="timeline-dot"></div>

                <div class="content-wrap">
                  <div class="content-header">
                    <div class="content-inner">
                      <div class="maxcoach-image image">
                        <img
                          class="animate-this"
                          decoding="async"
                          src="assets/img/clip-applying-to-university-online.gif"
                          alt="Career Success"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="content-main">
                    <div class="content-inner">
                      <div class="description">
                        <h5 class="title mb-3">Career Success </h5>
                        <p>Land in your dream job</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ================ End Feature Area ================= --> */}

      {/* <!-- ================ Start Registration Area ================= --> */}
      <section className="registration-area" id="book">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-5">
              <div className="section-title text-left text-white">
                <h2 className="text-white">
                  Register With Us <br /> Today!
                </h2>
                <p>
                  Learn diverse domains with us register now and our academic
                  counselor will be getting in touch with you shortly!
                </p>
              </div>
            </div>
            <div className="offset-lg-3 col-lg-4 col-md-12">
              <div className="course-form-section animate-this">
                <h3 className="text-white">Get in touch with us</h3>
                <p className="text-white">It is high time for learning</p>
                {message && (
                  <p
                    class={`alert text-left ${
                      success ? "alert-success" : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {message}
                  </p>
                )}
                <form
                  className="course-form-area contact-page-form course-form text-right"
                  id="myForm"
                  onSubmit={submit}
                >
                  <div className="col-md-12 form-group">
                    <input
                      name="user_name"
                      value={enteredName}
                      onChange={changeNameHandler}
                      onBlur={nameInputBlurHandler}
                      placeholder="Enter your name"
                      className="form-control"
                      required=""
                      type="text"
                    />

                    <input
                      name="user_email"
                      placeholder="Enter email address"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      className="form-control"
                      required=""
                      type="email"
                      value={enteredEmail}
                      onChange={changeEmailHandler}
                      onBlur={emailInputBlurHandler}
                    />

                    <input
                      name="user_phone"
                      placeholder="Enter phone number"
                      pattern="[0-9]{10}"
                      className="form-control"
                      required=""
                      type="tel"
                      value={enteredPhone}
                      onChange={changePhoneHandler}
                      onBlur={phoneInputBlurHandler}
                    />
                  </div>
                  <div className="col-lg-12 text-center">
                    <button className="genric-btn primary circle text-uppercase w-100">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="circle" className="circle"></div>
      <section className="popular-course-area section-gap">
        <div className="container">
          <div className="row justify-content-center section-title pb-4">
            <div className="col-lg-12 text-left">
              <p className="text-dark">
                <strong>What People Say About Us</strong>
              </p>
              <h2>Happy Students!</h2>
            </div>
          </div>
          <OwlCarousel className="owl-theme" {...options}>
            {testimonials.map((testimonial) => (
              <div className="single-popular-course reviews-item">
                <div className="details">
                  <div className="thumb">
                    <img
                      style={{ height: "80px", width: "80px" }}
                      src={testimonial.photo}
                      alt=""
                    />
                  </div>
                  <div className="testcont">
                    <div className="d-flex justify-content-between mb-2">
                      <p className="name">{testimonial.name}</p>
                    </div>
                    <h4>{testimonial.designation}</h4>
                    <p className="description">{testimonial.content}</p>
                    <div className="bottom d-flex mt-15">
                      <ul className="list">
                        <li>
                          <a href="#">
                            <i className="fa fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-star"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-star"></i>
                          </a>
                        </li>
                      </ul>
                      <p className="ml-20">5 Stars</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
}
