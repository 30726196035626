import "./App.css";
import "./assets/css/linearicons.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/nice-select.css";
import "./assets/css/hexagons.min.css";
import "./assets/css/main.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage title="EasyHiring" />} />
          <Route
            path="/about-us"
            element={<AboutUs title="About Us | EasyHiring" />}
          />
          <Route
            path="/contact"
            element={<Contact title="Contact | EasyHiring" />}
          />
          <Route
            path="/testimonials"
            element={<Testimonials title="Testimonials | EasyHiring" />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
      <BrowserRouter></BrowserRouter>
    </div>
  );
}

export default App;
