import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useEffect } from "react";

const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  loop: true,
  autoplay: true,
  navText: [
    "<img src='assets/img/prev.png'>",
    "<img src='assets/img/next.png'>",
  ],
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 4,
    },
  },
};

export default function AboutUs(props) {
  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <>
      <section className="home-banner-area">
        <div className="container">
          {/* <div className="row justify-content-center align-items-center">
        <div className="col-lg-10 banner-right">
          <img className="img-fluid animate-this" src="assets/img/urban-woman-sitting-at-a-table-6.gif" alt="" />
          <h1 className="text-white mb-2">
            About Us
          </h1>
          <div className="link-nav">
            <span className="box">
              <Link to="/">Home </Link>
              <i className="lnr lnr-arrow-right"></i>
              <Link to="/about-us">About Us</Link>
            </span>
          </div>
        </div>
      </div> */}
          <div className="row justify-content-between fullscreen align-items-center py-5">
            <div className="col-lg-6 col-md-12 home-banner-left text-left pb-5">
              <h1 className="text-white text-left">About Us</h1>
            </div>
            <div className="col-lg-6 col-md-12 home-banner-right">
              <img
                className="img-fluid animate-this mt-5"
                src="assets/img/3z_marketing_5.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ End banner Area ================= -->

  <!-- ================ Start Feature Area ================= --> */}
      {/* <section className="feature-area">
    <div className="container-fluid">
      <div className="feature-inner row">
        <div className="col-lg-2 col-md-6">
          <div className="feature-item d-flex">
            <i className="ti-book"></i>
            <div className="ml-20">
              <h4>New Classes</h4>
              <p>
                In the history of modern astronomy, there is probably no one greater leap forward.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="feature-item d-flex">
            <i className="ti-cup"></i>
            <div className="ml-20">
              <h4>Top Courses</h4>
              <p>
                In the history of modern astronomy, there is probably no one greater leap forward.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="feature-item d-flex border-right-0">
            <i className="ti-desktop"></i>
            <div className="ml-20">
              <h4>Full E-Books</h4>
              <p>
                In the history of modern astronomy, there is probably no one greater leap forward.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}

      <section className="feature-area section-gap-bottom">
        <div className="container">
          <div className="feature-inner row">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <p>
                Easy hiring is an Ed-Tech company with the core objective of
                serving Students, Universities, Organizations in all ways
                possible like Upskilling, offering placement opportunities,
                recruitment, and enhancing the learning curve of students by
                identifying the individual learning pattern of them and curating
                the content as this helps to catalyze the effective and
                efficient learning inside them. Our aim is to bridge the gap
                between the student and the industry ready employee and make
                them lead the digitized world for their rewarding career.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="counter-area section-gap">
        <div className="container section-gap">
          <div className="row align-items-center">
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp
                    end={100}
                    redraw={true}
                    suffix="+"
                    scrollSpyOnce={true}
                  >
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">Learning Hours</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={50} redraw={true} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">INDUSTRY EXPERTS</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={25000} redraw={true} suffix="+">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">ACTIVE LEARNERS</p>
              </div>
            </div>
            <div className="col-md-3 video-left mt-5">
              <div class="counter">
                <h2 class="timer count-title count-number">
                  <CountUp end={100} redraw={true} suffix="%">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h2>
                <p class="count-text ">STUDENT SATISFACTION</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ End Feature Area ================= -->

  <!-- ================- Start About Area ================= --> */}
      <section className="about-area section-gap">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 about-left">
              <img
                className="img-fluid animate-this"
                src="assets/img/clip-financial-report.gif"
                alt=""
              />
            </div>
            <div className="col-lg-8 col-md-12 about-right section-title">
              <h2>Our Mission</h2>
              <div>
                <p>
                  At Easy Hiring, we believe everyone should have the
                  opportunity to learn the emerging technologies and prove their
                  capability in their respective fields. With assessments, AI
                  powered reports and Programs authored by industry experts, our
                  platform helps individuals become industry ready. Our mission
                  is to train the world’s workforce in the careers of the
                  future. We partner with leading technology companies to learn
                  how technology is transforming industries, and teach the
                  critical tech skills that companies are looking for in their
                  workforce.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center section-title mt-5 pb-0">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="text-center">Our students are hired by</h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 logop-slider">
              <OwlCarousel className="owl-theme" {...options}>
                <div className="">
                  <img
                    className="img-fluid"
                    src="assets/img/Amazon_logo.png"
                    alt=""
                  />
                </div>
                <div className="">
                  <img
                    className="img-fluid"
                    src="assets/img/ibm-logo.png"
                    alt=""
                  />
                </div>
                <div className="">
                  <img
                    className="img-fluid"
                    src="assets/img/Infosys_logo.png"
                    alt=""
                  />
                </div>
                <div className="">
                  <img
                    className="img-fluid"
                    src="assets/img/Oracle_logo.png"
                    alt=""
                  />
                </div>
                <div className="">
                  <img
                    className="img-fluid"
                    src="assets/img/TCS-logo.png"
                    alt=""
                  />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================- End About Area ================= -->

  <!-- ================ Start Video Area ================= --> */}
      {/* <section className="video-area1 section-gap counter-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5 col-sm-12 mx-auto">
          <div class="section-title text-white section-gap text-center">
            <h2 class="text-white">Watch Our Trainers in Live Action</h2>
            <p class="mb-4">In the history of modern astronomy, there is probably no one greater leap forward than the building and launch of the space telescope known as the Hubble.</p>
            <a href="#" class="genric-btn default circle arrow">Book a demo<span class="lnr lnr-arrow-right"></span></a>
          </div>
        </div>
      </div>
    </div>
  </section> */}

      {/* <section className="section-gap-bottom text-center">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-4 col-sm-12 mx-auto">
          <img class="img-fluid mb-4 animate-this" src="assets/img/clip-applying-to-university-online.gif" alt=""/>
          <h4 className="mb-2 animate-this">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>
          </div>
        <div className="col-lg-4 col-sm-12 mx-auto">
          <img class="img-fluid mb-4 animate-this" src="assets/img/clip-applying-to-university-online.gif" alt=""/>
          <h4 className="mb-2 animate-this">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>
          </div>
        <div className="col-lg-4 col-sm-12 mx-auto">
          <img class="img-fluid mb-4 animate-this" src="assets/img/clip-applying-to-university-online.gif" alt=""/>
          <h4 className="mb-2 animate-this">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>
          </div>        
      </div>
    </div>
  </section> */}

      {/* <!-- ================ End Video Area ================= -->}

  
  <!-- ================ Start Video Area ================= --> */}
      {/* <!-- ================ End Video Area ================= -->}

  {/* <!-- ================ Start Feature Area ================= --> */}
      {/* <section className="other-feature-area">
    <div className="container">
      <div className="feature-inner row">
        <div className="col-lg-12">
          <div className="section-title text-left animate-this">
            <h2>
              Popular <br /> Programs
            </h2>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6">
          <div className="other-feature-item">
            <i className="ti-server"></i>
            <h4>Data Science</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--160">
          <div className="other-feature-item">
            <i className="ti-layout-slider"></i>
            <h4>Web Development</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--260">
          <div className="other-feature-item">
            <i className="ti-shield"></i>
            <h4>Cyber Security</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6">
          <div className="other-feature-item">
            <i className="ti-briefcase"></i>
            <h4>Finance</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--160">
          <div className="other-feature-item">
            <i className="ti-vector"></i>
            <h4>Machine Learning</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
        <div data-aos-mirror="true" className="col-lg-4 col-md-6 mt--260">
          <div className="other-feature-item">
            <i className="ti-headphone-alt"></i>
            <h4>Artificial Intelligence</h4>
            <div>
              <p>
                Lorem ipsum dolor sit amet consec tetur adipisicing elit, sed
                do eiusmod tempor incididunt labore. Lorem ipsum dolor sit
                amet consec tetur adipisicing elit, sed do eiusmod tempor
                incididunt labore.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}

      {/* <!-- ================ End Feature Area ================= --> */}

      {/* <!-- ================ Start Testimonials Area ================= -->  */}
    </>
  );
}
